import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Projects = () => (
  <Layout>
    <SEO title="Projects" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1 className="mb-4">Some <span className="primary">projects</span> of mine<span className="primary">.</span></h1>
          <p>In the process of gathering my projects and getting them posted. Check back later!</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Projects
